@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Albert+Sans:wght@400;700;800&display=swap');


.text-xxs {
    font-size: 0.5rem;
    line-height: 0.75rem;
  }